@charset "utf-8";

///////////////////////////////////////////////////////////
// featureList
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-featureList {
  display: grid;
  gap: 2rem clamp_size(20,49,992,1440);
  grid-template-columns: repeat(5, 1fr);
  @include mq-down(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include mq-down(sm) {
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

.p-featureList__item {
  display: grid;
  place-content: center;
  font-size: clamp_size(14,17,992,1440);
  font-weight: 500;
  height: 7.6rem;
  text-align: center;
  border: 1px solid $clr_light_brown;
}