@charset "utf-8";

///////////////////////////////////////////////////////////
// works
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-works__head {
  text-align: center;
}

.p-works__slider {
  margin-top: clamp_size(40, 60);

  .swiper {
    padding-bottom: clamp_size(40, 60) !important;

    &.is-stop {
      padding-bottom: 0 !important;

      .swiper-wrapper {
        display: flex;
        justify-content: center;
        width: min(92%,130rem);
        margin: 0 auto;
        @include mq-down(md) {
          max-width: 52rem;
          flex-direction: column;
        }
      }

      .swiper-slide {
        flex-shrink: 1;
        max-width: 65rem;

        &:not(:first-child) {
          margin-left: clamp_size(20,60,768,1440);
          @include mq-down(md) {
            margin-left: 0;
            margin-top: 3rem;
          }
        }
      }

      .swiper-pagination {
        display: none;
      }
    }
  }

  .swiper-pagination {
    line-height: 1;
  }

  .swiper-pagination-bullets {
    bottom: 0 !important;
  }
  
  .swiper-pagination-bullet {
    width: 1.1rem;
    height: 1.1rem;
    background-color: $clr_light_brown;
    margin: 0 clamp_size(10, 12) !important;
  }
}

.p-works__slide_link {
  display: block;

  @include hover {
    .p-works__slide_thumb {
      img {
        transform: scale(1.02);
        will-change: transform;
      }
    }
  }
}

.p-works__slide_thumb {
  overflow: hidden;
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;

  img {
    display: inline-block;
    width: 100%;
    aspect-ratio: 844/494;
    object-fit: cover;
    transition: transform .4s ease-out;
  }
  
  &--dummy {
    border: 1px solid $clr_medium_brown;
  }
}

.p-works__slide_text {
  margin-top: clamp_size(15, 30);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.p-voice__btn {
  text-align: center;
  margin-top: clamp_size(30, 50);
}