@charset "utf-8";

///////////////////////////////////////////////////////////
// quality
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-page-quality {

  /* エントランス
  ---------------------------------------------------------- */
  .entrance {
    .c-heading {
      @media screen and (max-width: 460px) {
        letter-spacing: -0.06em;
      }
    }
  }

  .entrance__text {
    text-align: center;
    margin-top: clamp_size(30, 60);
    @include mq-down(md) {
      max-width: 61rem;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
    }
  }

  /* 安心の標準装備
  ---------------------------------------------------------- */
  .equipment__about {
    margin-top: clamp_size(30, 60);
  }

    /* 品質保証
  ---------------------------------------------------------- */
  .assurance__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: clamp_size(30, 60);
    @include mq-down(md) {
      flex-direction: column;
    }
  }

  .assurance__icon {
    display: grid;
    place-content: center;
    place-items: center;
    width: 28rem;
    height: 28rem;
    border: 1px solid $clr_light_brown;
    border-radius: 50%;
    @include mq-down(lg) {
      width: 22rem;
      height: 22rem;
    }
    @include mq-down(md) {
      margin: 0 auto;
    }
  }

  .assurance__icon_title,
  .assurance__icon_num {
    font-weight: 600;
    font-family: $fnt_mincho;
    color: $clr_light_brown;
    line-height: normal;
    text-align: center;
  }

  .assurance__icon_title {
    font-size: clamp_size(16, 24);
  }
  
  .assurance__icon_num {
    font-size: clamp_size(44, 64);
  }

  .assurance__content {
    width: calc(100% - (28rem + 6rem));
    @include mq-down(lg) {
      width: calc(100% - (22rem + 3rem));
    }
    @include mq-down(md) {
      width: 100%;
      margin-top: clamp_size(20,30,375,768);
    }
  }

  .assurance__cautions {
    margin-top: 1rem;
  }

  .assurance__caution {
    font-size: clamp_size(12, 14);
    line-height: 1.78;
  }
}

  /* ⻑期優良住宅認定基準の仕様
  ---------------------------------------------------------- */
  .spec__inner {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    place-content: center;
    margin-top: clamp_size(30, 60);
    gap: clamp_size(10, 32);
    margin: 0 auto;
    @include mq-down(md) {
      grid-template-columns: repeat(2,45%);
    }
  }

  .spec__item {
    padding: clamp_size(16, 20);
    border: 1px solid $clr_light_brown;
    // min-width: 18%;
    display: grid;
    place-items: center;
    // @include mq-down(md) {
    //   width: calc(50% -  clamp_size(10, 32));
    // }
  }

  .spec__item_title,
  .spec__item_num {
    font-weight: 600;
    font-family: $fnt_mincho;
    color: $clr_light_brown;
    line-height: normal;
    text-align: center;
  }

  .spec__item_title {
    font-size: clamp_size(14, 20);
  }

  .spec__item_num {
    font-size: clamp_size(44, 64);
  }

  .spec__item_num span {
    font-size: clamp_size(14, 20);
  }
  
  .spec__notice {
    margin-top: clamp_size(20, 30);
    & p {
      font-size: clamp_size(12, 14);
      line-height: 1.5;
    }
  }