@charset "utf-8";

///////////////////////////////////////////////////////////
// 変数定義用SASS
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// ブレイクポイント
// ---------------------------------------------------------
$breakpoint_up: (
  sm: "screen and (min-width: 576px)",
  md: "screen and (min-width: 768px)",
  lg: "screen and (min-width: 992px)",
  xl: "screen and (min-width: 1200px)",
  xxl: "screen and (min-width: 1400px)"
);
$breakpoint_down: (
  sm: "screen and (max-width: 575.98px)",
  md: "screen and (max-width: 767.98px)",
  lg: "screen and (max-width: 991.98px)",
  xl: "screen and (max-width: 1199.98px)",
  xxl: "screen and (max-width: 1399.98px)"
);

// ---------------------------------------------------------
// レイアウト
// ---------------------------------------------------------
// コンテナ幅
$container_width: ( fluid:100%, md:720px, lg:960px, xl:1140px, xxl:1320px );

// 余白
$gap: 3rem; // 30px

// ---------------------------------------------------------
// z-index
// ---------------------------------------------------------
$z-navBtn: 500;
$z-nav: 400;
$z-header: 300;
$z-fixedItem: 200;

// ---------------------------------------------------------
// hover
// ---------------------------------------------------------
$opacity: .6;
$transition_opacity: opacity .3s ease;

// ---------------------------------------------------------
// hover
// ---------------------------------------------------------
$opacity: .6;
$transition_opacity: opacity .3s ease;

// ---------------------------------------------------------
// カラー
// ---------------------------------------------------------
// ブラウン系
$clr_brown: #4F2828;
$clr_medium_brown: #B69B7E;
$clr_light_brown: #B69C7E;
$clr_beige: #FBF8F2;
$clr_medium__beige: #F0DBC4;
$clr_light_beige: #EBE6DD;

// オレンジ系
$clr_orange: #EC8A17;

// レッド系
$clr_red: #C46868;

// グレー系
$clr_gray: #DCD6D6;
$clr_light_gray: #EAEAEA;

// ---------------------------------------------------------
// デフォルトフォント設定
// ---------------------------------------------------------
// フォントサイズ
$fnt_size_default: 1.7rem;

// 太さ
$fnt_weight_default: 400;

// 行間
$fnt_lh_default: 2.4;

// フォントファミリー
@import url('https://fonts.googleapis.com/css2?family=Cardo:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');

$fnt_en: 'Cardo', serif;
$fnt_faq: 'Cormorant Garamond', serif;
$fnt_gothic: 'Noto Sans JP', sans-serif;
$fnt_mincho: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "游明朝", YuMincho, "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;