@charset "utf-8";

///////////////////////////////////////////////////////////
// btn
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-btn {
  position: relative;
  display: inline-block;
  font-size: clamp_size(14, 17);
  font-family: $fnt_mincho;
  font-weight: 600;
  text-align: center;
  width: 100%;
  transition: background-color .4s ease-out;
  box-sizing: border-box;
  @include linkColor(#fff);

  &--small {
    padding: .4em 1.24em;
  }

  &--medium {
    font-size: clamp_size(15, 18);
    padding: .5em 1em;
    max-width: 39.2rem;
  }

  &--large {
    font-size: clamp_size(15, 18);
    padding: .5em 1em;
    max-width: 41.6rem;
  }
  
  &--brown {
    background-color: $clr_medium_brown;
    @include hover {
      background-color: #c9aa8a;
    }
  }
  
  &--orange {
    background-color: $clr_orange;
    @include hover {
      background-color: #ee9c3d;
    }
  }
  
  &--gray {
    background-color: $clr_light_gray;
    @include linkColor($clr_brown);
    @include hover {
      background-color: darken($clr_light_gray, 5%);
    }
  }
  
  &--rounded {
    border-radius: 50px;
  }

  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      margin-left: .2em;
    }
  }

  &--arrow-r {
    &::after {
      content: "\f105";
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
    }
  }

  &--arrow-l {
    &::after {
      content: "\f104";
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      position: absolute;
      top: 50%;
      left: 1em;
      transform: translateY(-50%);
    }
  }

  &--arrow-b {
    &::after {
      content: "\f107";
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-42%);
    }
  }
}