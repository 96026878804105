@charset "utf-8";

///////////////////////////////////////////////////////////
// sideMenu
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-sideMenu__title {
  font-size: clamp_size(18, 24);
  font-weight: 600;
  font-family: $fnt_mincho;
  line-height: 1.75;
  color: $clr_light_brown;
  padding-bottom: .3em;
  border-bottom: 1px solid $clr_light_brown;
  @include mq-down(md) {
    text-align: center;
    margin-bottom: clamp_size(10, 15);
  }
}

.p-sideMenu__categories {
  margin-top: clamp_size(24, 40);
}

.p-sideMenu__category {
  line-height: normal;

  & + & {
    margin-top: 1.2em;
  }
}

.p-sideMenu__category_link {
  position: relative;
  display: inline-block;
  font-weight: 500;
  line-height: normal;
  padding-left: 1.65em;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-45%);
    display: block;
    height: 2.9rem;
    width: 2.9rem;
    background: url("img/common/icon_arrow04.svg") no-repeat;
    background-size: cover;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease;
  }

  &:hover,
  &:focus,
  &.is-current {
    &::before {
      opacity: 1;
      visibility: visible;
    }
  }
}

.p-sideMenu__archive {
  margin-top: clamp_size(70, 100);
  @include mq-down(md) {
    margin-top: clamp_size(20, 30);
  }
}

.p-sideMenu__archive_selectWrap {
  position: relative;
  margin-top: clamp_size(24, 36);
  @include mq-down(md) {
    max-width: 18rem;
    margin-left: auto;
    margin-right: auto;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 4%;
    transform: translateY(-45%) rotate(90deg);
    display: block;
    height: 2.9rem;
    width: 2.9rem;
    background: url("img/common/icon_arrow04.svg") no-repeat;
    background-size: cover;
  }
}

.p-sideMenu__archive_select {
  width: 100%;
  padding: 0.25em 1.25em;
  font-size: clamp_size(14, 17);
  font-weight: 500;
  color: $clr_brown;
  border: 1px solid $clr_light_brown;
}