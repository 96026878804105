@charset "utf-8";

///////////////////////////////////////////////////////////
// document
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-page-document {

  .document__title {
    text-align: center;

    .sub,
    .main {
      display: block;
      font-family: $fnt_mincho;
    }

    .sub {
      font-size: clamp_size(16, 24);
      font-weight: 600;
      line-height: 1.75;
    }
    
    .main {
      font-size: clamp_size(22, 42);
      font-weight: 600;
      color: $clr_light_brown;
      line-height: 1;
      margin-top: .2em;
    }
  }

  .document__pamphlet {
    padding: clamp_size(30, 40) clamp_size(15, 50);
    background-color: #fff;
    margin-top: clamp_size(40, 60);
  }

  .document__pamphlet_head {
    text-align: center;
  }

  .document__pamphlet_content {
    margin-top: 3rem;
  }

  .document__pamphlet_list {    
    display: flex;
    justify-content: space-between;
    gap: clamp_size(20, 30);
    @include mq-down(lg) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .document__pamphlet_item {
    width: 31.2%;
    @include mq-down(lg) {
      width: clamp_size(303,403,768,992);
    }
    @include mq-down(md) {
      max-width: 50rem;
      width: 100%;
    }
  }

  .document__pamphlet_thumb {
    text-align: center;

    img {
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    }
  }

  .document__pamphlet_text {
    font-size: clamp_size(14,17,768,1440);
    font-weight: 500;
    margin-top: .5em;
    line-height: 2.4;
    text-align: center;
    @include mq-down(lg) {
      font-size: clamp_size(14,17,375,768);
    }
  }

  .document__paper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: clamp_size(30, 40) clamp_size(15, 120);
    margin-top: clamp_size(40, 60);
    @include mq-down(lg) {
      flex-direction: column;
    }
  }

  .document__paper__content {
    width: fit-content;
    @include mq-down(lg) {
      width: 100%;
    }
  }

  .document__paper__head {
    @include mq-down(lg) {
      text-align: center;
    }
  }

  .document__paper_text {
    font-size: clamp_size(14,17,768,1440);
    font-weight: 500;
    margin-top: 1.6em;
    @include mq-down(lg) {
      font-size: clamp_size(14,17,375,768);
      text-align: center;
      margin-top: 0;
    }
  }

  .document__paper_thumb {
    width: 47.5%;
    max-width: 50rem;
    @include mq-down(lg) {
      width: 100%;
      text-align: center;
      margin: clamp_size(20, 30) auto 0;
    }
  }
}