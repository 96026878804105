@charset "utf-8";

///////////////////////////////////////////////////////////
// squareLink
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-squareLink {
  position: relative;
  font-size: clamp_size(16, 18);
  font-weight: 600;
  font-family: $fnt_mincho;
  text-align: center;
  display: inline-block;
  max-width: 100%;
  padding: clamp_size(6, 8) 1em;
  border: 1px solid $clr_orange;
  color: $clr_orange;
  transition: color .3s ease, background-color .3s ease;

  &--small {
    width: clamp_size(160, 250);
  }

  &--middle {
    width: clamp_size(263, 363);
  }

  &--large {
    width: clamp_size(285, 385);
  }

  &--right {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      width: clamp_size(40, 60);
      height: 1px;
      background-color: $clr_orange;
    }
  }

  &--left {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      width: clamp_size(40, 60);
      height: 1px;
      background-color: $clr_orange;
    }
  }
  
  @include hover {
    color: #fff;
    background-color: $clr_orange;
  }
}