@charset "utf-8";

///////////////////////////////////////////////////////////
// category
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-page-category {
  .category__title {
    margin-bottom: .7em;
    line-height: 1;
  }
}