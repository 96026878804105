@charset "utf-8";

///////////////////////////////////////////////////////////
// archive
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-page-archive {
  .news__head {
    display: flex;
    justify-content: space-between;
    @include mq-down(sm) {
      flex-direction: column;
    }
  }

  .news__btn {
    font-size: clamp_size(22, 42);
    margin-top: auto;
    @include mq-down(sm) {
      margin-top: 2rem;
    }
  }

  .news__list {
    margin-top: clamp_size(40, 60);
  }
}