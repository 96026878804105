@charset "utf-8";

///////////////////////////////////////////////////////////
// priceCard
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-priceCard {
  display: grid;
  place-content: center;
  place-items: center;
  padding: 3rem;
  margin: 0 auto;
  background-color: #fff;
  max-width: 61.8rem;

  &--beige {
    background-color: $clr_beige;
  }

  &--plan {
    padding: clamp_size(15,30,992,1440);
    background-color: $clr_beige;

    .p-priceCard__title,
    .p-priceCard__taxPrice {
      font-size: clamp_size(14,17,992,1440);
    }

    .p-priceCard__price {
      font-size: clamp_size(16,25,992,1440);
    }
  }
}

.p-priceCard__title {
  font-weight: 500;
  margin-bottom: .2em;
}

.p-priceCard__price {
  font-size: clamp_size(20, 25);
  font-weight: 500;
  color: $clr_orange;
  line-height: 1;
  
  .price {
    display: inline-block;
    font-size: 2.24em;
    font-weight: 500;
    line-height: 1;
  }
}

.p-priceCard__taxPrice {
  font-weight: 500;
  margin-top: .4em;
}