@charset "utf-8";

///////////////////////////////////////////////////////////
// suvFv
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-subFv {
  position: relative;
  padding: clamp_size(94, 147) 0 clamp_size(73, 125);
  padding: clamp_size(104, 147) 0 clamp_size(83, 125);
  overflow: hidden;
  z-index: 1;

  &--error {
    .p-subFv__bg {
      img {
        @include mq-down(md) {
          object-position: top 0 left 20%;
        }
      }
    }
  }

  &--contact {
    .p-subFv__bg {
      img {
        @include mq-down(md) {
          object-position: top 0 left 30%;
        }
      }
    }
  }

  &--document {
    .p-subFv__bg {
      img {
        @include mq-down(md) {
          object-position: center;
        }
      }
    }
  }
}

.p-subFv__title {
  font-size: clamp_size(22, 32);
  font-weight: 600;
  font-family: $fnt_mincho;
  color: #fff;
  text-align: center;
  line-height: normal;
}

.p-subFv__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;

  img {
    width: 100%;
    height: clamp_size(220, 320);
    object-fit: cover;
  }
}