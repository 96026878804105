@charset "utf-8";

///////////////////////////////////////////////////////////
// index
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-page-home {

  /* FV
  ---------------------------------------------------------- */
  .fv {
    position: relative;
    height: clamp_size(568,800,768,1440);
    @include mq-down(md) {
      height: 60vh;
    }
  }

  .fv__inner {
    position: relative;
    display: flex;
    align-items: center;
    height: inherit;
    z-index: 1;
  }

  .fv__copy {
    font-size: clamp_size(26, 46);
    font-weight: 600;
    font-family: $fnt_mincho;
    line-height: 1.65;
    color: #fff;
  }

  .fv__vr {
    position: absolute;
    right: 0;
    bottom: clamp_size(16, 26);
    width: clamp_size(250, 392);
    z-index: 5;
  }

  .fv__vr_link {
    display: block;
    transition: transform .3s ease-out;
    @include hover {
      transform: translateY(-.5rem);
    }
  }

  .fv__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;

    img {
      width: 100vw;
      height: 100%;
      object-fit: cover;
      max-width: unset;
    }
  }

  /* イベント / お知らせ
  ---------------------------------------------------------- */
  .news__inner {
    margin-top: clamp_size(40, 60);
    display: grid;
    gap: 0 10%;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "news-head news-list"
      "news-btn news-list";
    @include mq-down(lg) {
      margin-top: clamp_size(16, 20);
      gap: 3rem;
      grid-template-columns: auto;
      grid-template-rows: auto;
      grid-template-areas:
        "news-head"
        "news-list"
        "news-btn";
    }
  }

  .news__head {
    grid-area: news-head;
    align-self: start;
  }

  .news__btn {
    grid-area: news-btn;
    align-self: start;
    margin-top: clamp_size(30, 40);
    @include mq-down(lg) {
      margin-top: clamp_size(5, 20);
      text-align: center;
    }
  }

  .news__list,
  .news__noPost {
    grid-area: news-list;
  }

  .news__list {
    min-width: clamp_size(460,700,992,1440);
    @include mq-down(lg) {
      min-width: 100%;
    }
  }

  .news__noPost {
    align-self: center;
    font-weight: 500;
  }

  .news__movie {
    & .news__movie-item {
      aspect-ratio: 16 / 9;
      height: auto;
      margin: 0 auto;
    }
  }

  /* 住む人の数だけある、家づくりの形
  ---------------------------------------------------------- */
  .concept__inner {
    display: grid;
    gap: clamp_size(30,60,992,1440) 8.8%;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "concept-head concept-thumb"
      "concept-text concept-thumb";
    margin-right: calc(50% - 50vw);
    @include mq-up(xxl) {
      margin-right: 0;
    }
    @include mq-down(md) {
      margin-right: auto;
      margin-left: auto;
      gap: clamp_size(30,40,375,768);
      grid-template-columns: auto;
      grid-template-rows: auto;
      grid-template-areas:
        "concept-head"
        "concept-thumb"
        "concept-text";
    }
  }

  .concept__head {
    grid-area: concept-head;
    align-self: start;
    @include mq-down(md) {
      text-align: center;
    }
  }

  .concept__text {
    grid-area: concept-text;
    align-self: start;
    @include mq-down(md) {
      margin-top: clamp_size(10,30,375,768);
    }
  }

  .concept__thumb {
    grid-area: concept-thumb;
    position: relative;
    max-width: 63.5rem;
    @include mq-down(md) {
      max-width: 54.5rem;
      right: -2.5vw;
      margin: 0 4%;
      justify-self: center;
      align-self: center;
    }

    &::before {
      content: " ";
      position: absolute;
      left: max(-5vw, -7.2rem);
      top: min(3.3vw, 4.8rem);
      width: 100%;
      aspect-ratio: 635 / 456;
      background-color: $clr_beige;
    }

    img {
      position: relative;
      z-index: 1;
    }
  }

  /* ハーフビルド新築住宅の仕組み
  ---------------------------------------------------------- */
  .structure {
    padding-bottom: clamp_size(140, 280);
  }

  .structure__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mq-down(md) {
      flex-direction: column;
    }
  }

  .structure__thumb {
    width: 37.6%;
    @include mq-down(md) {
      display: none;
    }
  }
  
  .structure__contents {
    width: 58%;
    @include mq-down(md) {
      width: 100%;
    }
  }

  .structure__contents_head {
    @include mq-down(md) {
      text-align: center;
    }
  }

  .structure__contents_thumb {
    display: none;
    @include mq-down(md) {
      display: block;
      text-align: center;
      margin-top: clamp_size(30,40,375,768);
    }
  }

  .structure__contents_text {
    margin-top: clamp_size(30,60,768,1440);
    @include mq-down(md) {
      margin-top: clamp_size(25,30,375,768);
    }
  }

  /* やわら木の家の特長
  ---------------------------------------------------------- */
  .feature {
    position: relative;
  }

  .feature__bg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;

    img {
      width: 100%;
      min-height: 14rem;
      object-fit: cover;
    }
  }

  .feature__inner {
    position: relative;
    padding: clamp_size(70, 140) 0;
    background-color: #fff;
    z-index: 5;
  }

  .feature__head {
    text-align: center;
  }

  .feature__list {
    display: grid;
    gap: clamp_size(25,60,768,1440);
    grid-template-columns: repeat(3, 1fr);
    margin-top: clamp_size(30,60);
    @include mq-down(md) {
      grid-template-columns: repeat(1, 1fr);
      gap: clamp_size(30,50,375,768);
    }
  }

  .feature__item_thumb {
    text-align: center;
  }

  .feature__item_title {
    margin-top: clamp_size(20, 30);
    text-align: center;

    .sub,
    .main {
      display: block;
      font-weight: 600;
      font-family: $fnt_mincho;
      line-height: 1;
    }

    .sub {
      font-size: clamp_size(14,18,768,1440);
      @include mq-down(md) {
        font-size: clamp_size(14,18,375,768);
      }
    }

    .main {
      font-size: clamp_size(19,24,768,1440);
      color: $clr_light_brown;
      margin-top: .8em;
      @include mq-down(md) {
        font-size: clamp_size(20,24,375,768);
      }
    }
  }

  .feature__item_text {
    margin-top: clamp_size(12, 22);
  }

  .feature__btn {
    margin-top: clamp_size(30,60);
    text-align: center;
  }

  /* プラン・間取り
  ---------------------------------------------------------- */
  .plan__head {
    text-align: center;
  }

  .plan__inner {
    margin-top: clamp_size(40, 60);
  }

  .plan__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: -4rem;
    margin-left: -6rem;
    @media screen and (max-width: 1420px) {
      margin-top: -3rem;
      margin-left: -3rem;
    }
    @include mq-down(xl) {
      margin-left: -2rem;
    }
    @include mq-down(lg) {
      margin-left: -3rem;
    }
    @include mq-down(md) {
      flex-direction: column;
      flex-wrap: nowrap;
      margin-left: 0;
    }
  }

  .plan__item {
    width: 30%;
    max-width: 39.2rem;
    padding: 2rem;
    font-family: $fnt_gothic;
    background-color: #fff;
    margin-top: 4rem;
    margin-left: 6rem;
    @media screen and (max-width: 1420px) {
      margin-top: 3rem;
      margin-left: 3rem;
    }
    @include mq-down(xl) {
      margin-left: 2rem;
    }
    @include mq-down(lg) {
      width: 33.2rem;
      max-width: 100%;
      margin-left: 3rem;
    }
    @include mq-down(md) {
      width: 100%;
      margin-left: 0;
    }
  }
  
  .plan__item_title {
    display: grid;
    place-content: center;
    place-items: center;
    text-align: center;
    padding: 1rem;
    background-color: $clr_light_brown;
    // min-height: 10rem;
    
    .number,
    .name {
      display: block;
      font-size: clamp_size(14, 17);
      font-size: clamp_size(15,17,992,1440);
      font-weight: 700;
      color: #fff;
      @include mq-down(lg) {
        font-size: clamp_size(16,17,375,992);
      }
    }

    .number {
      font-family: $fnt_en;
      line-height: normal;
    }

    .name {
      line-height: 1.4;
      margin-top: .3em;
    }
  }

  .plan__item_thumb {
    margin-top: 2rem;

    img {
      width: 100%;
      aspect-ratio: 352 / 238;
      object-fit: cover;
    }
  }

  .plan__item_body {
    margin-top: 2rem;
  }
  
  .plan__item_totalPrice {
    text-align: center;
    
    .unit {
      display: inline-block;
      font-weight: 500;
    }

    .price {
      display: inline-block;
      font-size: 1.8em;
      font-weight: 500;
      color: $clr_orange;
      line-height: 1;
      margin-left: .5em;

      .price-unit {
        font-size: 0.6875em;
      }
    }
  }

  .plan__item_detailPrice {
    display: grid;
    place-content: center;
    padding: 1.2rem;
    margin-top: 2rem;
    background-color: $clr_beige;
    text-align: center;

    &:not(:first-of-type) {
      margin-top: 1rem;
    }
  }

  .plan__item_detailPrice_title {
    font-size: clamp_size(14,17,992,1440);
    font-weight: 700;
    line-height: normal;
    @include mq-down(lg) {
      font-size: clamp_size(14,15,375,992);
    }

    .br {
      display: none;
      @include mq-down(xl) {
        display: block;
      }
      @include mq-down(lg) {
        display: none;
      }
    }
  }
  
  .plan__item_detailPrice_price {
    font-size: clamp_size(14,17,992,1440);
    font-weight: 500;
    line-height: 2;
    margin-top: .4em;
    @include mq-down(lg) {
      font-size: clamp_size(14,16,375,992);
    }
  }

  .price__builtPrice {
    margin-top: clamp_size(40,60);
  }

  .price__btn {
    text-align: center;
    margin-top: clamp_size(40,60);
  }

  /* 資料のダウンロードはこちら
  ---------------------------------------------------------- */
  .download {
    text-align: center;
  }

  .download__inner {
    padding: clamp_size(20, 50);
    background: url("img/top/img_top10@2x.png") no-repeat;
    background-size: cover;
  }

  .download__contents {
    padding: clamp_size(28, 38);
    background: rgba(255, 255, 255, 0.90);
  }

  .download__btn {
    margin-top: 1rem;

    .c-btn {
      margin: 0 auto;
    }
  }

  /* よくある質問
  ---------------------------------------------------------- */
  .faq__head {
    text-align: center;
  }

  .faq__inner {
    margin-top: clamp_size(30, 40);
  }

  .faq__box {
    &:not(:first-child) {
      margin-top: clamp_size(20, 30);
    }
  }
  
  .faq__wrap {
    display: flex;
  }
  
  .faq__icon {
    display: inline-block;
    font-size: clamp_size(26, 40);
    font-family: $fnt_faq;
    font-weight: 700;
    color: $clr_light_brown;
    line-height: 1;
  }

  .faq__content {
    margin-left: clamp_size(15, 20);
  }
  
  /* Question */
  .faq__question {
    position: relative;
    padding: 2rem clamp_size(50, 90) 2rem clamp_size(20, 30);
    background-color: #fff;
    cursor: pointer;
  
    &::before,
    &::after {
      position: absolute;
      top: 3.8rem;
      right: clamp_size(20, 40);
      content: '';
      display: block;
      width: clamp_size(15, 18);
      height: .2rem;
      background-color: $clr_light_brown;
      border-radius: 5px;
    }
  
    &::after {
      transform: rotate(90deg);
      transition: all .3s ease;
    }
  
    &.is-active {
      &::after {
        transform: rotate(0);
      }
    }
  
    .faq__icon {
      transform: translateY(.1rem);
    }
  }
  
  .faq__question_title {
    font-weight: 500;
  }
  
  /* Answer */
  .faq__answer {
    display: none;
    padding: 0 clamp_size(50, 90) 2rem clamp_size(20, 30);
    background-color: #fff;
    transition: opacity .3s ease;
    transition-delay: .2s;
    opacity: 0;
  
    &.is-active {
      opacity: 1;
      display: block;
    }
  
    .faq__icon {
      transform: translateY(.6rem);
    }
  }

  /* やわら木の家について
  ---------------------------------------------------------- */
  .about__inner {
    position: relative;
    margin-right: calc(50% - 50vw);
    @include mq-up(xxl) {
      margin-right: 0;
    }
    @include mq-down(md) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  .about__head {
    text-align: center;
  }

  .about__text {
    width: 64.3%;
    max-width: 83.8rem;
    margin-top: clamp_size(40, 60);
    text-align: justify;
    @include mq-down(lg) {
      width: 72%;
    }
    @include mq-down(md) {
      width: 100%;
    }
  }

  .about__bg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-35%);
    width: 100%;
    max-width: 87.6rem;
    z-index: -1;
  
    img {
      width: 100%;
      object-fit: cover;
      min-height: 59rem;
    }
  }
}