@charset "utf-8";

///////////////////////////////////////////////////////////
// date
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-date {
  line-height: normal;

  time {
    font-size: clamp_size(14, 17);
    font-family: $fnt_mincho;
    font-weight: 600;
  }
}