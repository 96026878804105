@charset "utf-8";

///////////////////////////////////////////////////////////
// conversion
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-conversion {
  padding: clamp_size(70, 120) 0;
  background: url("img/common/img_cta@2x.png") no-repeat;
  background-size: cover;
  background-position: center;
}

.p-conversion__head {
  text-align: center;
}

.p-conversion__cards {
  display: grid;
  gap: 2.4rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: clamp_size(36, 46);
  @include mq-down(md) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
}

.p-conversion__card {
  padding: clamp_size(40,50,768,1440);
  background-color: #fff;
  @include mq-down(md) {
    padding: clamp_size(24,40,576,768);
  }
}

.p-conversion__card_title {
  font-size: clamp_size(14, 17);
  font-weight: 500;
  text-align: center;
}

.p-conversion__card_link {
  margin-top: 1.4rem;

  .c-btn {
    margin: 0 auto;
  }
}