@use "../foundation/mixins" as *;

.u-icon {
  &-right {
    &-outlook {
      @include icon("\f105", "right");
    }
  }

  // &-envelope {
  //   @include icon("\f0e0");
  // }

  // &-facebook {
  //   @include icon("\f39e", "left", "brands");
  // }

  // &-twitter {
  //   @include icon("\f099", "left", "brands");
  // }
}
