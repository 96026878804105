@charset "utf-8";

///////////////////////////////////////////////////////////
// mainContents
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-mainContents {
  width: 74%;
  @include mq-down(md) {
    width: 100%;
  }
}