@charset "utf-8";

///////////////////////////////////////////////////////////
// archiveList
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-archiveList {
  display: grid;
  gap: clamp_size(20,60,768,1440);
  grid-template-columns: repeat(3, 1fr);
  margin-top: 2rem;
  @include mq-down(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq-down(md) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 440px) {
    grid-template-columns: repeat(1, 1fr);
  }
}