@charset "utf-8";

///////////////////////////////////////////////////////////
// header
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.header {
  position: fixed;
  top: 3rem;
  left: 0;
  right: 0;
  width: 100%;
  height: 5rem;
  z-index: $z-header;
  @include mq-down(md) {
    top: 2rem;
  }
  @include mq-down(sm) {
    top: 1rem;
  }

  &.is-active {
    .header__title_logo_img {
      path {
        fill: $clr_brown !important;
      }
    }

    .header__title_description {
      color: $clr_brown;
    }

    .header__navLink {
      @include linkColor($clr_brown);

      &::after {
        background-color: $clr_brown;
      }
    }

    .header__navBtn_line {
      background-color: $clr_brown;
    }

    .header__navBtn_inner {
      &::before {
        color: $clr_brown;
      }
    }
  }
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}

.header__title {
  display: flex;
  align-items: center;
}

.header__title_logo {
  position: relative;
  width: clamp_size(120, 170);
  line-height: 1;
}

.header__title_logo_img {
  path {
    fill: #fff;
    transition: fill .4s cubic-bezier(.165,.84,.44,1);
  }
}

.header__title_logo_link {
  display: block;
}

.header__title_description {
  font-size: 1.2rem;
  font-family: $fnt_mincho;
  font-weight: 600;
  color: #fff;
  line-height: 1.5;
  margin-left: clamp_size(10, 20);
  transition: color .4s cubic-bezier(.165,.84,.44,1);
  @include mq-down(sm) {
    display: none;
  }
}

.header__nav {
  height: inherit;
  margin-left: auto;
  @include mq-down(xl) {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    padding: 2.93vw 2.56vw;
    background-color: $clr_beige;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    overflow-x: hidden;
    z-index: $z-nav;

    &.is-open {
      pointer-events: auto;
    }
  }
}

.header__nav_inner {
  height: 100%;
  @include mq-down(xl) {
    position: relative;
    width: 40%;
    overflow-y: auto;
    background-color: #fff;
  }
  @include mq-down(md) {
    width: 45%;
  }
  @include mq-down(sm) {
    width: 100%;
  }
}

.header__nav_bg {
  display: none;
  @include mq-down(xl) {
    display: block;
    position: relative;
    width: 60%;
    height: 100%;
    text-align: center;
  }
  @include mq-down(md) {
    width: 55%;
  }
  @include mq-down(sm) {
    display: none;
  }
}

.header__nav_bg_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top 0 left 20%;
}

.header__nav_bg_logo {
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: clamp_size(167,207,576,1200);
  z-index: 1;
}

.header__navList {
  display: flex;
  align-items: center;
  height: inherit;
  @include mq-down(xl) {
    flex-direction: column;
    align-items: flex-start;
    padding: clamp_size(40,50,375,1200) clamp_size(20,50,375,1200);
    height: fit-content;
    background-color: #fff;
    overflow-y: auto;
  }
  @include mq-down(sm) {
    width: 100%;
    max-width: 40rem;
    margin: 0 auto;
  }
}

.header__navItem {
  & + & {
    margin-left: clamp_size(20,30,1200,1440);
    @include mq-down(xl) {
      margin-left: 0;
      margin-top: 3rem;
    }
  }

  &--top {
    display: none;
    @include mq-down(xl) {
      display: block;
    }
  }

  &--navBtn {
    display: none;
    @include mq-down(xl) {
      display: block;
      width: 100%;
      margin-top: 5rem !important;
    }
  }
}

.header__navLink {
  position: relative;
  display: inline-block;
  text-align: center;
  transition: color .4s cubic-bezier(.165,.84,.44,1);
  @include linkColor(#fff);
  @include mq-down(xl) {
    @include linkColor($clr_brown);
    text-align: left;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s cubic-bezier(.39,.575,.565,1), visibility .4s cubic-bezier(.39,.575,.565,1);
    @include mq-down(xl) {
      background-color: $clr_brown;
    }
  }

  .en {
    display: block;
    font-size: 1rem;
    font-family: $fnt_en;
    font-weight: 700;
    line-height: normal;
    @include mq-down(xl) {
      font-size: clamp_size(12,14,375,1200);
    }
  }

  .ja {
    display: block;
    font-size: clamp_size(14,16,1200,1440);
    font-family: $fnt_mincho;
    font-weight: 600;
    line-height: normal;
    margin-top: 0.1875em;
    @include mq-down(xl) {
      font-size: clamp_size(16,22,375,1200);
      margin-top: 0.13em;
    }
  }

  @include hover {
    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
}

.header__btnList {
  display: flex;
  align-items: center;
  margin-left: clamp_size(20,30,1200,1440);
  @include mq-down(xl) {
    margin-left: auto;
  }
  @include mq-down(md) {
    display: none;
  }
}

.header__btnItem {
  & + & {
    margin-left: clamp_size(20,30,1200,1440);
  }
}

/* スマホメニュー用 ボタンリンク
---------------------------------------------------------- */
.header__navBtnList {
  text-align: center;
}

.header__navBtnItem {
  & + & {
    margin-top: 1.2rem;
  }

  .c-btn {
    margin: 0 auto;
  }
}

/* ハンバーガーメニュー
---------------------------------------------------------- */
.header__navOpenBtn {
  display: none;
  @include mq-down(xl) {
    display: block;
    margin-left: 1.4rem;

    .header__navBtn_inner {
      &::before {
        content: "MENU";
        color: #fff;
      }
    }
  }
}

.header__navBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header__navBtn_inner {
  position: relative;
  width: clamp_size(38, 40);
  height: clamp_size(28, 32);

  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: clamp_size(11, 12);
    font-family: $fnt_en;
    font-weight: 700;
    line-height: 1;
    letter-spacing: .04em;
    color: #fff;
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out, color .4s cubic-bezier(.165,.84,.44,1);
  }
}

.header__navBtn_line {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: .2rem;
  background-color: #fff;
  transition: transform .5s ease-in-out, background-color .4s cubic-bezier(.165,.84,.44,1);

  &:nth-of-type(1) {
    top: 0;
    left: 0;
  }

  &:nth-of-type(2) {
    top: clamp_size(9, 10);
    left: 0;
  }
}

.header__navCloseBtn {
  display: none;
  @include mq-down(xl) {
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;

    .header__navBtn_inner {
      &::before {
        content: "CLOSE";
        color: $clr_brown;
      }
    }
  
    .header__navBtn_line {
      background-color: $clr_brown;
  
      &:nth-of-type(1) {
        top: 0;
        left: 0;
        transform: translateY(5px) rotate(20deg);
      }
    
      &:nth-of-type(2) {
        top: 1rem;
        left: 0;
        transform: translateY(-5px) rotate(-20deg);
      }
    }
  }
}

/* メニュー展開時 背景固定
---------------------------------------------------------- */
body.is-open {
  height: 100%;
  overflow: hidden;
}