@charset "utf-8";

///////////////////////////////////////////////////////////
// moreLink
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-moreLink {
  position: relative;
  display: inline-block;
  font-size: clamp_size(15, 18);
  font-weight: 600;
  font-family: $fnt_mincho;
  line-height: 1;
  padding-right: 2.5rem;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: url("img/common/icon_arrow02.svg") no-repeat;
    background-size: cover;
    display: block;
    width: 2.4rem;
    height: 2.4rem;
  }
}