@charset "utf-8";

///////////////////////////////////////////////////////////
// heading
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-blogCard {
  border: 1px solid $clr_light_brown;
  background-color: #fff;
}

.p-blogCard__link {
  display: block;

  @include hover {
    .p-blogCard__thumb {
      img {
        transform: scale(1.05);
      }
    }
  }
}

.p-blogCard__thumb {
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 279/177;
    transition: transform .4s ease-out;
  }
}

.p-blogCard__body {
  padding: clamp_size(15, 20);
}

.p-blogCard__head {
  margin-bottom: clamp_size(5,10);
}

.p-blogCard__title {
  font-weight: 500;
  line-height: 1.8;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.p-blogCard__bottom {
  margin-top: clamp_size(5,20);

  &--flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.p-blogCard__categories {
  font-size: 0;
  margin-top: -.5rem;
  margin-left: -.5rem;
}

.p-blogCard__category {
  display: inline-block;
  font-size: clamp_size(12, 14);
  font-weight: 500;
  line-height: 2.2;
  padding: .4em .7em;
  margin-top: .5rem;
  margin-left: .5rem;
  background-color: $clr_beige;
}