@charset "utf-8";

///////////////////////////////////////////////////////////
// single
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-page-article {

  /* article
  ---------------------------------------------------------- */
  .article {
    margin: clamp_size(65, 95) 0 clamp_size(70, 140);
  }

  h1.article__title {
    font-size: clamp_size(28, 42);
    font-weight: 600;
    font-family: $fnt_mincho;
    color: $clr_light_brown;
    line-height: 1.5625;
    padding-bottom: .35em;
    border-bottom: 1px solid $clr_light_brown;
  }

  .article__date {
    margin-top: clamp_size(20, 40);
  }

  .article__btn {
    text-align: center;
    margin-top: clamp_size(70, 100);
  }

  /* content
  ---------------------------------------------------------- */
  .content {
    margin-top: clamp_size(52, 72);

    h2 {
      font-size: clamp_size(26, 32);
      font-weight: 600;
      font-family: $fnt_mincho;
      color: $clr_light_brown;
      line-height: 1.3;
      margin-bottom: 1.6em;
    }
  
    h3 {
      position: relative;
      font-size: clamp_size(20, 26);
      font-weight: 600;
      font-family: $fnt_mincho;
      color: $clr_light_brown;
      line-height: 1.6;
      padding-left: .65em;
      margin-bottom: 1.6em;
  
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: block;
        width: 4px;
        height: 100%;
        background-color: $clr_light_brown;
      }
    }
  
    h4, h5, h6 {
      font-size: clamp_size(15, 18);
      font-weight: 700;
      line-height: 1.4;
      margin-bottom: 1em;
    }
  
    p {
      font-size: clamp_size(14, 17);
      text-align: justify;
      line-height: 2.4;
  
      & + p,
      & + a {
        margin-top: 2rem;
      }
  
      & + * {
        margin-top: clamp_size(20, 40);
      }
    }
  
    strong {
      font-size: clamp_size(14, 15);
      line-height: 1.8;
    }
  
    blockquote {
      position: relative;
      margin: clamp_size(40, 60) 0 !important;
      padding: clamp_size(30, 60) clamp_size(40, 130);
      background-color: $clr_beige;
  
      &::before,
      &::after {
        content: "“";
        position: absolute;
        font-size: clamp_size(44, 64);
        font-weight: 500;
        color: $clr_brown;
        line-height: 1;
      }
  
      &::before {
        top: -.5rem;
        left: clamp_size(12, 52);
        transform: scale(-1, 1) rotate(180deg);
      }
  
      &::after {
        bottom: -.5rem;
        right: clamp_size(12, 52);
        transform: scale(-1, 1) rotate(360deg);
      }
    }
  
    figure {
      & + p {
        margin-top: 2rem;
      }
  
      & + * {
        margin-top: clamp_size(20, 40);
      }
  
      figcaption {
        font-size: clamp_size(12, 14);
        margin-top: .3em;
      }
  
      img {
        height: auto;
      }
    }
  
    img {
      height: auto;
    }
  
    ol {
      list-style-type: none;
      counter-reset: item;
  
      & + * {
        margin-top: clamp_size(20, 40);
      }
  
      li {
        position: relative;
        font-size: clamp_size(14, 15);
        padding-left: 1.5em;
  
        & + li {
          margin-top: 1rem;
        }
        
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          counter-increment: item;
          content: counter(item)'.';
          font-size: clamp_size(14, 15);
          font-weight: bold;
          color: $clr_brown;
        }
      }
    }
    
    ul {
      list-style: none;
  
      & + * {
        margin-top: clamp_size(20, 40);
      }
      
      li {
        position: relative;
        font-size: clamp_size(14, 15);
        padding-left: 1.5em;
  
        & + li {
          margin-top: 1rem;
        }
  
        &::before {
          content: "・";
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  
    a {
      position: relative;
      display: inline-block;
      font-size: clamp_size(14, 15);
      font-family: $fnt_mincho;
      font-weight: 600;
      color: $clr_light_brown;
      text-decoration: underline;
      word-wrap: break-word;
      text-underline-offset: 0.3ex;
  
      & + p,
      & + a {
        margin-top: 2rem;
      }
  
      & + * {
        margin-top: clamp_size(20, 40);
      }
  
      @include hover {
        text-decoration: none;
      }
    }
  
    .tableWrapper {
      width: 100%;
      padding-bottom: 1.5rem;
      white-space: nowrap;
      overflow-x: auto;
  
      & + p {
        margin-top: 2rem;
      }
  
      & + * {
        margin-top: clamp_size(20, 40);
      }
    }
  
    table {
      width: 82.5rem;
      border-spacing: 0;
      border-collapse: collapse;
  
      thead {
        border-bottom: none;
      }
      
      tr {
        background-color: transparent !important;
      }
      
      th,
      td {
        font-size: clamp_size(15, 18);
        padding: 1.2em 1.35em;
        border: 1px solid $clr_light_brown;
  
        small {
          display: block;
          font-size: clamp_size(14, 15);
          line-height: 1.6;
          margin-top: .5rem;
        }
      }
      
      th {
        font-weight: bold;
        text-align: left;
        background: $clr_beige;
      }
  
      a {
        white-space: nowrap;
      }
    }
  }

  /* related
  ---------------------------------------------------------- */
  .related__list {
    display: grid;
    gap: clamp_size(20,60,768,1440);
    grid-template-columns: repeat(4, 1fr);
    @include mq-down(lg) {
      max-width: 60rem;
      margin: 0 auto;
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 440px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}