@charset "utf-8";

///////////////////////////////////////////////////////////
// formInput
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-formInput {
  width: 100%;
  font-size: clamp_size(14, 17);
  padding: .4em 1.7em;
  background-color: $clr_beige;
  box-sizing: border-box;
  @include mq-down(md) {
    padding: .7em 1em;
  }

  &::placeholder {
    color: $clr_gray;
  }

  &:focus {
    outline: 2px solid $clr_light_brown;
  }

  &--textarea {
    min-height: 24rem;
  }
}