@charset "utf-8";

///////////////////////////////////////////////////////////
// voice
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-voice {
  position: relative
}

.p-voice__head {
  text-align: center;
}

.p-voice__slider {
  margin-top: clamp_size(35, 55);

  .swiper {
    padding: .5rem 0 0 0;

    &.is-stop {
      .swiper-wrapper {
        display: flex;
        justify-content: center;
        width: min(92%, 65rem);
        margin: 0 auto;
        @include mq-down(md) {
          max-width: 52rem;
          flex-direction: column;
        }
      }

      .swiper-slide {
        flex-shrink: 1;

        &:not(:first-child) {
          margin-left: clamp_size(20,60,768,1440);
          @include mq-down(md) {
            margin-left: 0;
            margin-top: 3rem;
          }
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 4.6rem;
    height: 4.6rem;

    &::after {
      content: "";
      width: 4.6rem;
      height: 4.6rem;
      transition: background-image .3s ease-out;
    }
  }

  .swiper-button-prev {
    left: 2.4vw;

    &::after {
      background: url("img/common/icon_arrow01.svg") no-repeat;
      background-size: cover;
    }

    @include hover {
      &::after {
        background-image: url("img/common/icon_arrow01_brown.svg");
      }
    }
  }

  .swiper-button-next {
    right: 2.4vw;

    &::after {
      background: url("img/common/icon_arrow05.svg") no-repeat;
      background-size: cover;
    }

    @include hover {
      &::after {
        background-image: url("img/common/icon_arrow05_brown.svg");
      }
    }
  }
}

.p-voice__slide {
  background-color: #fff;
  border: 1px solid $clr_light_brown;
  transition: transform .3s ease-out;
  @include hover {
    transform: translateY(-.5rem) !important;
  }
}

.p-voice__slide_inner {
  height: 100%;
}

.p-voice__slide_link {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 4rem 2rem;
  box-sizing: border-box;
}

.p-voice__slide_icon {
  width: 9.2rem;
  height: 9.2rem;
  margin: 0 auto;

  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
  }
}

.p-voice__slide_text {
  margin-top: 1.7em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.p-voice__slide_moreLink {
  margin-top: auto;
  text-align: right;
}

.p-voice__btn {
  text-align: center;
  margin-top: clamp_size(40, 60);
}

.p-voice__bg {
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 130rem;
  z-index: -1;

  img {
    width: 100%;
    object-fit: cover;
    min-height: 59rem;
  }
}