@charset "utf-8";

///////////////////////////////////////////////////////////
// headingHead
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-headingHead {
  text-align: center;
}