@charset "utf-8";

///////////////////////////////////////////////////////////
// footer
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.footer {
  padding: clamp_size(50, 100) 0 clamp_size(25, 35);
  background-color: $clr_medium_brown;
}

.footer__inner {
  display: flex;
  justify-content: space-between;
  @include mq-down(lg) {
    flex-direction: column;
  }
}

.footer__head {
  width: 15rem;
  @include mq-down(lg) {
    margin: 0 auto;
    text-align: center;
  }
}

.footer__logo {
  line-height: 1;
}

.footer__logo_link {
  display: inline-block;
  & svg {
    width: 100%;
  }
}

.footer__sns {
  margin-top: 2rem;
  @include mq-down(lg) {
    margin-top: clamp_size(16, 20);
  }
}

.footer__sns_instagram {
  display: inline-block;
  width: clamp_size(28, 34);
  height: clamp_size(28, 34);
  line-height: 1;
}

.footer__nav {
  display: flex;
  justify-content: space-between;
  width: clamp_size(720,948,992,1440);
  max-width: 94.8rem;
  @include mq-down(lg) {
    width: 100%;
    margin: 2rem auto 0;
  }
  @include mq-down(md) {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-areas:
      "navList01 navList02"
      "navList03 navList03";
    gap: 2rem 4rem;
    max-width: 42rem;
  }
  @include mq-down(md) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
    "navList01"
    "navList02"
    "navList03";
  }
}

.footer__navList {
  font-size: clamp_size(13, 15);
  margin-top: -.8em;

  &:nth-child(1) {
    @include mq-down(md) {  
      grid-area: navList01;
      display: grid;
      gap: 0 1rem;
      grid-template-columns: repeat(2, auto);
    }
  }

  &:nth-child(2) {
    @include mq-down(md) {  
      grid-area: navList02;
    }
  }

  &:nth-child(3) {
    @include mq-down(md) {  
      grid-area: navList03;
      display: grid;
      gap: 0 1rem;
      grid-template-columns: repeat(2, auto);
    }
    @include mq-down(sm) {
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(2, auto);
      grid-template-areas:
      "navItemBottom01 navItemBottom02 navItemBottom03"
      "navItemBottom04 navItemBottom04 navItemBottom04";
      
      .footer__navItem {
        &:nth-child(1) {
          grid-area: navItemBottom01;
        }
        &:nth-child(2) {
          grid-area: navItemBottom02;
        }
        &:nth-child(3) {
          grid-area: navItemBottom03;
        }
        &:nth-child(4) {
          grid-area: navItemBottom04;
        }
      }
    }
  }
}

.footer__navItem_link {
  position: relative;
  font-size: clamp_size(13, 15);
  font-family: $fnt_mincho;
  font-weight: 600;
  padding-top: .3em;
  padding-bottom: .3em;
  @include linkColor(#fff);

  &--outlook {
    padding-right: 1em;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: block;
      width: 1.5rem;
      height: 1.3rem;
      background: url("img/common/icon_arrow03.svg") no-repeat;
      background-size: cover;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease;
  }

  @include hover {
    &::before {
      opacity: 1;
      visibility: visible;
    }
  }
}

.footer__navSubList {
  @include mq-down(md) {
    display: grid;
    gap: 0 1rem;
    grid-template-columns: repeat(3, auto);
  }
  @include mq-down(sm) {
    grid-template-columns: repeat(2, auto);
  }
}

.footer__navSubItem {
  padding-left: 1em;

  &::before {
    content: "-";
    font-size: clamp_size(13, 15);
    color: #fff;
  }
}

.footer__navSubItem_link {
  position: relative;
  font-size: clamp_size(13, 15);
  font-family: $fnt_mincho;
  font-weight: 600;
  padding-top: .3em;
  padding-bottom: .3em;
  @include linkColor(#fff);

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease;
  }

  @include hover {
    &::before {
      opacity: 1;
      visibility: visible;
    }
  }
}

.footer__contents {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  padding: 3.5rem 0 clamp_size(35, 80);
  border-top: 1px solid #fff;
  @include mq-down(xl) {
    flex-direction: column-reverse;
  }
  @include mq-down(md) {
    padding-top: 4rem;
  }
}

.footer__info {
  display: flex;
  @include mq-down(xl) {
    justify-content: center;
    margin-top: 4rem;
  }
  @include mq-down(sm) {
    flex-direction: column;
    margin-top: 2.5rem;
  }
}

.footer__info_contents {
  & + & {
    margin-left: clamp_size(28,48,768,1440);
    @include mq-down(sm) {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}

.footer__info_contents_title {
  font-size: clamp_size(12, 15);
  font-weight: 700;
  color: #fff;
}

.footer__info_contents_text {
  font-size: clamp_size(12, 15);
  font-weight: 500;
  color: #fff;
}

.footer__map {
  width: 30%;
  height: 100%;
  aspect-ratio: 390 / 194;
  @include mq-down(xl) {
    width: 100%;
    max-width: 52rem;
    margin: 0 auto;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.footer__bottom {
  display: flex;
  justify-content: center;
  @include mq-down(sm) {    
    flex-direction: column-reverse;
    align-items: center;
  }
}

.footer__copyright,
.footer__appeal {
  font-size: clamp_size(11, 12);
  font-weight: 500;
  color: #fff;
}

.footer__appeal {
  margin-left: 2rem;
  @include mq-down(sm) {
    margin-left: 0;
  }
}