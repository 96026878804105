@charset "utf-8";

///////////////////////////////////////////////////////////
// aside
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-aside {
  width: 21.38%;
  @include mq-down(md) {
    width: 100%;
    max-width: 35rem;
    margin: 5rem auto 0;
  }
}