@charset "utf-8";

///////////////////////////////////////////////////////////
// breadcrumb
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-breadcrumb {
  line-height: 1.6;

  span[property="itemListElement"] {
    position: relative;
    display: inline-block;
  
    &:not(:first-of-type) {
      margin-left: 3.8rem;
  
      &::before {
        content: "";
        position: absolute;
        top: 55%;
        left: -3.2rem;
        display: block;
        width: 2.4rem;
        height: 1px;
        background-color: $clr_light_brown;
      }
    }
  }

  span[property="name"] {
    position: relative;
    display: inline-block;
    padding: .2rem 0;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: $fnt_mincho;
    line-height: normal;

    display: inline-block;
    vertical-align: middle;
    max-width: 30rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include mq-down(md) {
      max-width: clamp_size(100, 200, 375, 768);
    }
  
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: .1rem;
      display: block;
      width: 100%;
      height: 1px;
      background-color: $clr_brown;
      transition: opacity .2s ease-out;
      opacity: 0;
    }
  }
  
  a[property="item"] {
    @include hover {
      span[property="name"] {
        &::after {
          opacity: 1;
        }
      }
    }
  }
}