@charset "utf-8";

///////////////////////////////////////////////////////////
// formRadio
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-formRadio {
  .mwform-radio-field {
    display: block;
  }

  .horizontal-item {
    margin-left: 0 !important;
  }
}

.c-formRadio__input {
  opacity: 0;
  position: absolute;

  + span.mwform-radio-field-text {
    position: relative;
    display: inline-block;
    padding: .3em .3em .2em 1.6em;
    font-size: clamp_size(14, 17);
    font-weight: 500;
    line-height: 1;
    cursor: pointer;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-40%);
      content: "";
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid $clr_light_brown;
      box-sizing: border-box;
    }

    &::after {
      content: "";
      display: none;
    }
  }

  &:focus + span.mwform-radio-field-text {
    outline: 2px solid $clr_medium_brown;
  }

  &:checked + span.mwform-radio-field-text::after {
    content: "";
    position: absolute;
    top: clamp_size(4,6);
    left: 0;
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background-color: $clr_medium_brown;
  }
}