@charset "utf-8";

///////////////////////////////////////////////////////////
// plan
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-page-plan {

  /* エントランス
  ---------------------------------------------------------- */
  .about {
    .c-heading {
      @media screen and (max-width: 460px) {
        letter-spacing: -0.06em;
      }
    }
  }

  .about__text {
    margin-top: clamp_size(30, 60);
    // text-align: justify;
    text-align:center;
  }

  .about__price {
    margin-top: clamp_size(40, 60);
  }


  .case {
    margin-top: clamp_size(70, 140);
  }

  .case__tabs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid $clr_medium_brown;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display:none;
    }
  }

  .case__tab {
    width: 32.46%;
    min-width: 22rem;
    @include mq-down(md) {
      min-width: 24rem;
    }

    &:not(:first-child) {
      margin-left: clamp_size(10, 15);
    }

    &.is-tab-active {
      .case__tab_btn {
        background-color: $clr_medium_brown;
        pointer-events: none;
        
        .number,
        .name {
          color: #fff;
        }
      }
    }
  }

  .case__tab_btn {
    display: grid;
    place-content: center;
    place-items: center;
    width: 100%;
    min-height: clamp_size(92,122,768,1440);
    padding: clamp_size(10,16,768,1440) 0;
    background-color: $clr_beige;
    cursor: pointer;
    white-space: nowrap;
    transition: background-color .3s ease;
    @include mq-down(md) {
      min-height: 9.2rem;
      padding: 1rem;
    }

    .number,
    .name {
      font-size: clamp_size(15,20,768,1440);
      font-weight: 700;
      color: $clr_brown;
      transition: color .3s ease;
      @include mq-down(md) {
        font-size: 1.5rem;
      }
    }

    .number {
      font-family: $fnt_en;
      line-height: normal;
    }

    .name {
      line-height: 1.4;
      margin-top: .3em;
    }
  }

  .case__contents {
    display: none;

    &.is-tab-active {
      display: block;
    }
  }

  .case__contents_inner {
    display: grid;
    padding-top: clamp_size(40,60,992,1440);
    gap: clamp_size(20,60,768,1440);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "price thumb"
      "about cad";
    @include mq-down(lg) {
      grid-template-areas:
        "price price"
        "about about"
        "thumb cad";
    }
    @include mq-down(md) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas:
        "price"
        "thumb"
        "about"
        "cad";
    }
    &.flat {
      & .case__contents_cad {
        grid-row: 2 / 3;
        grid-column: 1 / 3;
        text-align: center;
        & img {
          width: unset;
          max-width: 100%;
        }
        @include mq-down(md) {
          grid-row: unset;
          grid-column: unset;
        }
      }
    }
  }

  /* price */
  .case__contents_price {
    grid-area: price;
    display: flex;
    flex-direction: column;
    padding: clamp_size(15, 30);
    border: 1px solid $clr_light_brown;
    // aspect-ratio: 618 / 379;
    @include mq-down(lg) {
      display: block;
      width: 52rem;
      justify-self: center;
      aspect-ratio: auto;
    }
    @include mq-down(sm) {
      width: 100%;
    }
  }

  .case__contents_price_title {
    font-size: clamp_size(20,27,992,1440);
    font-weight: 700;
    line-height: 1.4;
    @include mq-down(md) {
      font-size: clamp_size(17,20,360,992);
    }

    .num,
    .text {
      display: block;
      text-align: center;
    }

    .text {
      margin-top: .5rem;
    }
  }

  .case__contents_price_detail {
    margin-block: clamp_size(20,20,992,1440);
    font-size: clamp_size(16,18,992,1440);
    line-height: 1.8;
    text-align: center;
  }

  .case__contents_price_area {
    margin-top: clamp_size(15,24,992,1440);
    line-height: 1;
  }

  .case__contents_price_card {
    margin-top: auto;
    width: 100%;
    // height: 55%;
    padding: clamp_size(15,20,992,1440);
    @include mq-down(lg) {
      margin-top: 2rem;
      height: unset;
    }
  }

  /* thumb */
  .case__contents_thumb {
    grid-area: thumb;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // aspect-ratio: 618 / 379;
    }
  }

  /* about */
  .case__contents_about {
    grid-area: about;
    @include mq-down(lg) {
      max-width: 72rem;
      justify-self: center;
    }
  }

  /* cad */
  .case__contents_cad {
    grid-area: cad;

    img {
      width: 100%;
    }
  }
}

.case__contents {
  text-align: center;
}