
///////////////////////////////////////////////////////////
// form
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-form {
  margin-top: clamp_size(30, 50);
  border-top: 1px solid $clr_light_brown;

  /* 確認画面
  ---------------------------------------------------------- */
  &--confirm {
    .p-form__otherNotice {
      margin-top: 0;
    }

    .p-form__address,
    .p-form__subAddress {
      margin-top: 0;
    }

    .p-form__address_prefectures,
    .p-form__age {
      font-size: clamp_size(14, 17);
      font-weight: 500;
      
      &::after {
        display: none;
      }
    }

    .c-formRadio {
      font-size: clamp_size(14, 17);
      font-weight: 500;
    }

    .p-form__btn {
      display: flex;
      justify-content: center;
      @include mq-down(md) {
        flex-direction: column;
        align-items: center;
      }

      .c-btn {
        &:not(:first-child) {
          margin-left: 2rem;
          @include mq-down(md) {
            margin-left: 0;
            margin-top: 1.6rem;
          }
        }
      }
    }
  }

  /* 完了画面
  ---------------------------------------------------------- */
  &--complete {
    border-top: none;
    padding-top: clamp_size(10, 50);

    .p-form__complete_thumb {
      text-align: center;
    }
    
    .p-form__complete_title {
      text-align: center;
      margin-top: 4rem;
    }
    
    .p-form__complete_text {
      margin-top: clamp_size(30, 60);
      text-align: center;
      @include mq-down(sm) {
        text-align: left;
      }

      &:not(:first-child) {
        margin-top: clamp_size(20, 30);
      }
    }
  }

  /* エラー */
  .mw_wp_form.mw_wp_form_send_error {
    font-size: clamp_size(14, 16);
    text-align: center;
    padding: 3rem 0;
  }

  .error {
    display: none;
    font-size: clamp_size(14, 15) !important;
    font-weight: 500;
    color: $clr_red !important;
    &::before {
      content: "\f06a";
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      margin-right: 0.4em;
    }
  }
}

/* フォームレイアウト
---------------------------------------------------------- */
.p-form__block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0;
  border-bottom: 1px solid $clr_light_brown;
  @include mq-down(md) {
    flex-direction: column;
  }

  &--multiline {
    align-items: flex-start;

    .p-form__label {
      padding: .4em 0;
      @include mq-down(md) {
        padding: 0;
      }
    }
  }
}

.p-form__label {
  font-size: clamp_size(14, 17);
  font-weight: 500;
  line-height: 1.8;
  width: 20rem;
  @include mq-down(md) {
    width: 100%;
  }

  /* 必須ラベル */
  .require {
    display: inline-block;
    font-size: clamp_size(14, 17);
    color: $clr_orange;
    margin-left: .1em;
  }
}

.p-form__label_text {
  display: flex;
  align-items: center;
  font-size: clamp_size(14, 17);
  font-weight: 500;
}

.p-form__field {
  width: min(78.8%, 84.4rem);
  font-weight: 500;
  @include mq-down(md) {
    width: 100%;
    margin-top: .8rem;
  }

  &--privacy {
    @include mq-down(md) {
      margin-top: 2rem;
    }
  }
}

/* ご住所 */
.p-form__post {
  display: flex;
  align-items: center;

  .c-formInput {
    width: min(50%, 29.4rem);
  }
}

.p-form__post_label {
  font-size: clamp_size(14, 17);
  font-weight: 500;
  line-height: 1.8;
  margin-right: clamp_size(15, 30);
}

.p-form__address {
  display: flex;
  align-items: center;
  margin-top: clamp_size(15, 30);
  @include mq-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.p-form__address_prefectures {
  width: 33%;
  max-width: 28rem;
  @include mq-down(sm) {
    width: 50%;
  }
}

.p-form__address_municipalities {
  width: 36.7%;
  max-width: 31rem;
  min-width: 31rem;
  margin-left: clamp_size(15, 30);
  @include mq-down(sm) {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-top: clamp_size(15,20,375,768);
  }
}

.p-form__subAddress {
  margin-top: clamp_size(15, 30);
}

/* ご年齢 */
.p-form__age {
  width: 33%;
  max-width: 28rem;
  @include mq-down(sm) {
    width: 50%;
  }
}

/* ご年齢 */
.p-form__otherNotice {
  margin-top: 1rem;
}

/* プライバシー確認 */
.p-form__privacy {
  margin-top: 3rem;
  text-align: center;
}

.p-form__privacy_text {
  font-size: clamp_size(14, 17);
  font-weight: 500;
  @include mq-down(md) {
    line-height: 1.2;
  }
}

.p-form__privacy_link {
  position: relative;
  font-weight: 500;
  @include linkColor($clr_light_brown);
  @include hover {
    text-decoration: underline;
    text-underline-offset: 0.3em;
  }
}

/* フォームボタン */
.p-form__btn {
  text-align: center;
  margin-top: clamp_size(40, 60);
}