@charset "utf-8";

///////////////////////////////////////////////////////////
// writer
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-writer__inner {
  border: 1px solid $clr_light_brown;
  padding: clamp_size(35,70,768,1440) clamp_size(56,116,768,1440);
  @include mq-down(md) {
    padding: clamp_size(25,35,375,768) clamp_size(26,56,375,768);
  }
}

.p-writer__head {
  text-align: center;
}

.p-writer__profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: clamp_size(30,40,768,1440);
  @include mq-down(md) {
    flex-direction: column;
  }
}

.p-writer__profile_thumb {
  width: 16rem;
  height: 16rem;
  
  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
  }
}

.p-writer__profile_info {
  width: calc(100% - 5.6% - 16rem);
  padding: 2rem 0;
  border-bottom: 1px solid $clr_light_brown;
  @include mq-down(md) {
    width: 100%;
  }

  &.is-notPhoto {
    width: 100%;
  }

  p {
    font-weight: 500;
    line-height: 1.88;
  }

  .nameWrap {
    display: flex;
    align-items: center;

    .position {
      margin-right: 1em;
    }

    .name {
      font-size: clamp_size(16, 20);
      font-weight: 600;
      font-family: $fnt_mincho;
    }
  }

  .license {
    margin-top: .4em;
  }
}

.p-writer__profile_about {
  margin-top: clamp_size(30,40,768,1440);
  @include mq-down(md) {
    margin-top: clamp_size(20,30,375,768);
  }
}

.p-writer__profile_about_text {
  line-height: 2.1;
}