@charset "utf-8";

///////////////////////////////////////////////////////////
// pagerWrap
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-pagerWrap {
  margin-top: 5rem;
  @include mq-down(md) {
    margin-top: clamp_size(50, 70);
  }
}