@charset "utf-8";

///////////////////////////////////////////////////////////
// noPost
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-noPost {
  font-weight: 500;
  text-align: center;
  margin-top: clamp_size(40, 60);

  &--left {
    text-align: left;
  }
}