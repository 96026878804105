@charset "utf-8";

///////////////////////////////////////////////////////////
// formCaution
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-formCaution__item {
  font-size: clamp_size(12, 13);
  padding-left: 1.2em;
  text-indent: -1.2em;
  line-height: 1.7;

  &:not(:first-child) {
    margin-top: .5rem;
  }
}