@charset "utf-8";

///////////////////////////////////////////////////////////
// breadcrumb
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-breadcrumbWrap {
  padding-top: 2rem;

  &--beige {
    background-color: $clr_beige;
  }
}