@charset "utf-8";

///////////////////////////////////////////////////////////
// btnWrap
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-btnWrap {
  text-align: center;
  margin-top: clamp_size(40, 60);
}