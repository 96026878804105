@charset "utf-8";

///////////////////////////////////////////////////////////
// error
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-page-error {
  .error {
    text-align: center;
  }

  .error__message {
    margin-top: clamp_size(40, 60);
  }
}