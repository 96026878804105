@charset "utf-8";

///////////////////////////////////////////////////////////
// section
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-section {
  padding: clamp_size(70, 140) 0;

  &--beige {
    background-color: $clr_beige;
  }

  &--white {
    background-color: #fff;
  }

  &--t100 {
    padding: clamp_size(70, 100) 0 clamp_size(70, 140);
  }
}