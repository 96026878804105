@charset "utf-8";

///////////////////////////////////////////////////////////
// feature
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-page-feature {

  /* 多機能な家
  ---------------------------------------------------------- */
  .multi__list {
    display: grid;
    gap: 6rem;
    gap: clamp_size(20,60,768,1440);
    grid-template-columns: repeat(2, 1fr);
    margin-top: clamp_size(30, 60);
    @include mq-down(md) {
      gap: clamp_size(30,40,375,768);
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .multi__item_thumb {
    text-align: center;
  }

  .multi__item_title {
    font-size: clamp_size(16, 24);
    font-family: $fnt_mincho;
    font-weight: 600;
    color: $clr_light_brown;
    line-height: 1.75;
    text-align: center;
    margin-top: .8em;
  }

  .multi__item_text {
    margin-top: .4em;
    text-align: justify;
  }

  /* 安心の住宅機能で 暮らしを守る家
  ---------------------------------------------------------- */
  .feature__about {
    margin-top: clamp_size(40, 60);
  }

  .feature__detail {
    margin-top: clamp_size(40, 60);
  }

  .feature__list {
    display: flex;
    justify-content: space-between;

    display: grid;
    gap: clamp_size(35,55,992,1440);
    grid-template-columns: repeat(4, 1fr);
    @include mq-down(lg) {
      display: grid;
      gap: 3rem;
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq-down(sm) {
      grid-template-columns: repeat(1, 1fr);
      gap: clamp_size(15,30,375,576);
    }
  }

  .feature__item {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: clamp_size(35,45,992,1440) clamp_size(10,20,992,1440);

    &:nth-child(1) {
      .feature__item_text {
        display: flex;
        justify-content: center;

        .text {
          text-align: center;

          &:not(:first-child) {
            @include mq-down(lg) {
              margin-left: 2rem;
            }
          }
        }
      }
    }
  }

  .feature__item_thumb {
    display: grid;
    place-content: center;
    place-items: center;
    text-align: center;
    height: clamp_size(73,93,992,1440);
  }

  .feature__item_text {
    margin-top: 2rem;
    font-size: clamp_size(14,17,992,1440);
    font-weight: 600;
    font-family: $fnt_mincho;
    text-align: center;
    line-height: normal;
    @include mq-down(lg) {
      font-size: clamp_size(14,17,375,992);
    }
  }

  /* 建築コストを抑えて、「好き」にお金をかける
  ---------------------------------------------------------- */
  .skeleton__about {
    margin-top: clamp_size(30, 60);
  }
}