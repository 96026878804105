@charset "utf-8";

///////////////////////////////////////////////////////////
// popup
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-popup {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background: rgba(182, 156, 126, 0.30);
  opacity: 0;
  visibility: hidden;
  transition: opacity .4s ease, visibility .4s ease;
}

.p-popup__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 92vw;
  max-width: 107rem;
  background-color: #fff;
}

.p-popup__content {
  position: relative;
  padding: clamp_size(27, 70);

  lite-youtube.p-popup__movie {
    max-width: 100%;

    &::after {
      padding-bottom: 56.12% !important;
    }
  }
}

.p-popup__close {
  position: absolute;
  top: clamp_size(5, 20);
  right: clamp_size(5, 20);
  width: clamp_size(20, 25);
  height: clamp_size(20, 25);
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 100%;
    height: clamp_size(3, 4);
    background-color: $clr_brown;
    content: "";
  }

  &::before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  &::after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
}

body {
  &.is-popupOpen {
    overflow: hidden;

    .p-popup {
      opacity: 1;
      visibility: visible;
    }
  }
}