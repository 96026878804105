@charset "utf-8";

///////////////////////////////////////////////////////////
// contact
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-page-contact {
  
  /* お電話でのお問い合わせ
  ---------------------------------------------------------- */
  .tel {
    padding-bottom: 0;
  }

  .tel__inner {
    display: grid;
    place-items: center;
    place-content: center;
    text-align: center;
    padding: clamp_size(20, 40) clamp_size(15, 40);
    margin-top: clamp_size(40, 60);
    background-color: $clr_beige;
  }

  .tel__announce {
    font-weight: 500;
  }

  .tel__numWrap {
    margin-top: clamp_size(18, 38);
  }

  .tel__num {
    position: relative;
    font-size: clamp_size(32, 42);
    font-weight: 600;
    font-family: $fnt_mincho;
    color: $clr_light_brown;
    line-height: 1;
    padding-left: clamp_size(38, 46);
    transition: color .3s ease;
  
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: clamp_size(36, 46);
      height: clamp_size(36, 46);
      background: url("img/common/icon_call.svg") no-repeat;
      background-size: cover;
    }
    
    @include hover {
      color: $clr_orange;
    }
  }

  .tel__time {
    font-weight: 500;
  }
}