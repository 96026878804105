@charset "utf-8";

///////////////////////////////////////////////////////////
// newsList
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-newsList {
  border-top: 1px solid $clr_light_beige;
}

.p-newsItem {
  border-bottom: 1px solid $clr_light_beige;
}

.p-newsItem__link {
  display: flex;
  align-items: center;
  color: $clr_brown;
  padding: clamp_size(15, 28) 0;
  @include mq-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include hover {
    .p-newsItem__title {
      color: $clr_orange;
    }
  }
}

.p-newsItem__title {
  font-family: $fnt_gothic;
  font-weight: 500;
  transition: color .3s ease;
  margin-left: 5%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  @include mq-down(sm) {
    margin-left: 0;
    margin-top: .2em;
  }
}