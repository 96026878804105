@charset "utf-8";

///////////////////////////////////////////////////////////
// pageTop
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

body {
  position: relative;
}

.p-pageTop {
  position: fixed;
  right: 4.8%;
  bottom: clamp_size(30, 35);
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s ease, visibility .35s ease;
  z-index: $z-fixedItem;

  &.is-show {
    opacity: 1;
    visibility: visible;
  }
}

.p-pageTop__link {
  position: relative;
  display: inline-block;
  transition: $transition_opacity;

  @include hover {
    opacity: $opacity;
  }
}

.p-pageTop__icon {
  transition: opacity .4s cubic-bezier(.165,.84,.44,1), visibility .4s cubic-bezier(.165,.84,.44,1);

  &--brown {
    &.is-active {
      opacity: 0;
      visibility: hidden;
    }
  }

  &--white {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;

    &.is-active {
      opacity: 1;
      visibility: visible;
    }
  }

}