@charset "utf-8";

///////////////////////////////////////////////////////////
// inner
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-inner {
  @include mq-down(md) {
    max-width: 52rem;
    margin: 0 auto;
  }

  &--w992 {
    @include mq-down(lg) {
      max-width: 52rem;
      margin: 0 auto;
    }
  }
}