@charset "utf-8";

///////////////////////////////////////////////////////////
// formCheckbox
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-formCheckbox {
  font-size: clamp_size(14, 17);
  font-weight: 500;

  .mwform-checkbox-field {
    display: block;
  }

  .horizontal-item {
    margin-left: 0 !important;
  }
}

.c-formCheckbox__input {
  opacity: 0;
  position: absolute;

  + span.mwform-checkbox-field-text {
    position: relative;
    display: inline-block;
    padding: .3em .3em .2em 1.6em;
    font-size: clamp_size(14, 17);
    font-weight: 500;
    line-height: 1;
    cursor: pointer;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-40%);
      content: "";
      width: 1.6rem;
      height: 1.6rem;
      background-color: #fff;
      border: 1px solid $clr_light_brown;
      box-sizing: border-box;
    }

    &::after {
      content: "";
      display: none;
    }
  }

  &:focus + span.mwform-checkbox-field-text {
    outline: 2px solid $clr_medium_brown;
  }

  &:checked + span.mwform-checkbox-field-text::after {
    content: "";
    position: absolute;
    top: clamp_size(6, 8);
    left: 0.6rem;
    display: block;
    width: 0.25rem;
    height: 0.8rem;
    transform: rotate(45deg);
    border-bottom: 3px solid #b69c7e;
    border-right: 3px solid #b69c7e;
  }
}