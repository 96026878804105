@charset "utf-8";

///////////////////////////////////////////////////////////
// formSelect
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-formSelect {
  position: relative;

  &::after {
    content: "\f107";
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
    font-size: 1.4rem;
    font-weight: 900;
    color: $clr_light_brown;
    pointer-events: none;
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-46%)
  }

  select {
    font-weight: 600;
    font-family: $fnt_mincho;
    color: $clr_light_brown;
    background-color: #fff;
    outline: 1px solid $clr_light_brown;
  }
}