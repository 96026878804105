@charset "utf-8";

///////////////////////////////////////////////////////////
// pager
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.wp-pagenavi {
  display: flex;
  align-items: center;
  justify-content: center;

  a,
  span {
    padding: 0 !important;
  }

  .page,
  .current,
  .previouspostslink,
  .nextpostslink,
  .extend {
    margin: 0 clamp_size(5, 10);
  }
  
  .page,
  .current,
  .previouspostslink,
  .nextpostslink {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp_size(14, 17);
    font-weight: 600;
    font-family: $fnt_mincho;
    width: clamp_size(31, 41);
    height: clamp_size(31, 41);
    color: #fff;
  }

  .page,
  .previouspostslink,
  .nextpostslink {
    color: $clr_light_brown;
    position: relative;
    border: 1px solid $clr_light_brown !important;
    background-color: #fff;
    transition: color .3s ease-out, background-color .3s ease-out;

    @include hover {
      color: #fff;
      background-color: $clr_light_brown;
      border: 1px solid $clr_light_brown !important;
    }
  }

  span.current {
    color: #fff;
    background-color: $clr_light_brown;
    border: 1px solid $clr_light_brown !important;
    pointer-events: none;
    box-sizing: content-box;
  }

  .previouspostslink,
  .nextpostslink {
    &::after {
      display: inline-block;
      vertical-align: middle;
      transition: color .3s ease-out;
    }
    @include hover {
      &::after {
        background: url("img/common/icon_arrow04_wh.svg") no-repeat;
      }
    }
  }

  .previouspostslink {    
    &::after {
      content: "";
      background: url("img/common/icon_arrow04.svg") no-repeat;
      background-size: cover;
      display: block;
      width: 2.9rem;
      height: 2.9rem;
      transform: rotate(180deg);
    }
  }

  .nextpostslink {
    &::after {
      content: "";
      background: url("img/common/icon_arrow04.svg") no-repeat;
      background-size: cover;
      display: block;
      width: 2.9rem;
      height: 2.9rem;
    }
  }

  .first,
  .last {
    display: none;
  }

  .extend {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: $clr_light_brown;
    width: 1rem;
    height: 1rem;
    border-radius: 10px;
    border: none;
    transform: translateY(-5px);

    // &:last-of-type {
    //   display: none;
    // }
  }
}