@charset "utf-8";

///////////////////////////////////////////////////////////
// diy
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-diy {
  position: relative;
}

.p-diy__head {
  text-align: center;
}

.p-diy__contents {
  margin-top: clamp_size(40, 60);
}

.p-diy__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -4rem;
  margin-left: -6rem;
  @media screen and (max-width: 1420px) {
    margin-top: -3rem;
    margin-left: -3rem;
  }
  @include mq-down(sm) {
    flex-direction: column;
    flex-wrap: nowrap;
    margin-left: 0;
  }
}

.p-diy__item {
  width: 21.4%;
  max-width: 28rem;
  margin-top: 4rem;
  margin-left: 6rem;
  @media screen and (max-width: 1420px) {
    margin-top: 3rem;
    margin-left: 3rem;
  }
  @include mq-down(lg) {
    width: 28rem;
  }
  @include mq-down(md) {
    width: clamp_size(240,280,576,768);
  }
  @include mq-down(sm) {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
}

.p-diy__btn {
  text-align: center;
  margin-top: clamp_size(40, 60);
}

.p-diy__bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 130rem;
  z-index: -1;

  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}