
@charset "utf-8";

///////////////////////////////////////////////////////////
// fadeInAnime
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.fadeInAnime {
  opacity: 0;
  visibility: hidden;
}