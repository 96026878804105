@charset "utf-8";

///////////////////////////////////////////////////////////
// formStep
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-formStep {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: min(90%, 84.4rem);
  margin: clamp_size(50, 60) auto clamp_size(75, 95);

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 100%;
    height: 1px;
    background-color: $clr_light_brown;
    z-index: 1;
  }
}

.p-formStep__item {
  position: relative;
  display: grid;
  place-content: center;
  text-align: center;
  height: 4.5rem;
  line-height: 1;
  z-index: 2;
}

.p-formStep__item_icon {
  display: inline-block;
  width: 2.1rem;
  height: 2.1rem;
  background-color: $clr_medium__beige;
  border-radius: 50%;

  &.is-current {
    width: 4.5rem;
    height: 4.5rem;
    background-color: $clr_light_brown;
  }
}

.p-formStep__item_text {
  position: absolute;
  bottom: -2em;
  left: 50%;
  transform: translateX(-50%);
  width: 4em;
  font-weight: 500;
}