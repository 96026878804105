@charset "UTF-8";

@use "../setting/variables" as *;
@use "mixins" as *;

* {
  min-height: 0vw; /* clamp関数 safari対策 */
}

html,
body {
  color: $clr_brown;
  font-weight: $fnt_weight_default;
  font-size: 10px;
  line-height: $fnt_lh_default;
  font-family: $fnt_gothic;
}

div, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  box-sizing: border-box;
  font-size: $fnt_size_default;
  font-weight: $fnt_weight_default;
}

a {
  color: $clr_brown;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: $clr_brown;
    text-decoration: none;
    cursor: pointer;
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
  height: auto;
}

// ---------------------------------------------------------
// 共通パーツ
// ---------------------------------------------------------
ul,
ol,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
figure,
p {
  margin: 0;
  padding: 0;
}

p,
li,
dt,
dd {
  font-size: clamp_size(14, 17);
}

strong {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

ol, ul {
  list-style: none;
}

// フォームリセット
input,
button,
select,
textarea {
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;

  &::placeholder {
    color: #c4c4c4;
  }
}

textarea {
  resize: vertical;
}

input[type="checkbox"],
input[type="radio"] {
  appearance: none;
  display: none;
}

input[type="submit"],
input[type="button"],
// label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}