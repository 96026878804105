@charset "utf-8";

///////////////////////////////////////////////////////////
// contentsBox
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-contentsBox {
  padding: clamp_size(15, 60);
  background-color: #fff;

  &--beige {
    background-color: $clr_beige;
  }
}