@charset "utf-8";

///////////////////////////////////////////////////////////
// heading
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-heading {
  
  .en {
    display: block;
    font-size: clamp_size(16, 20);
    font-family: $fnt_en;
    font-weight: 700;
    color: $clr_light_brown;
    text-transform: capitalize;

    .uppercase {
      text-transform: uppercase;
    }

    .capitalize {
      text-transform: capitalize;
    }
  }
  
  .ja {
    display: block;
    font-size: clamp_size(22, 42);
    font-weight: 600;
    font-family: $fnt_mincho;
    color: $clr_light_brown;
    line-height: normal;
  }

  /* Feature */
  .sub {
    display: block;
    font-size: clamp_size(16, 24);
    font-weight: 600;
    font-family: $fnt_mincho;
    line-height: normal;
    text-align: center;
  }
  
  .main {
    display: block;
    font-size: clamp_size(22, 42);
    font-weight: 600;
    font-family: $fnt_mincho;
    color: $clr_light_brown;
    line-height: normal;
    text-align: center;
    margin-top: 0.1em;
  }

  &--middle {
    font-size: clamp_size(16, 24);
    font-weight: 500;
  }

  &--large {
    font-size: clamp_size(26, 32);
    font-weight: 600;
    font-family: $fnt_mincho;
    color: $clr_light_brown;
    line-height: 1.3;
  }

  &--xlarge {
    font-size: clamp_size(22, 42);
    font-weight: 600;
    font-family: $fnt_mincho;
    color: $clr_light_brown;
    line-height: normal;
  }

  &--white {
    .en,
    .ja {
      color: #fff;
    }
  }
}