@charset "utf-8";

///////////////////////////////////////////////////////////
// container
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-container {
  width: min(92%, 130rem);
  margin: 0 auto;
}

.l-container-w1070 {
  width: min(92%, 107rem);
  margin: 0 auto;
}